<template>
  <div
    class="input__w"
    :class="[
      { wide },
      { mobileMaxWidth },
      { isLandingPage },
      { 'with-icon': withIcon },
    ]"
  >
    <div class="input">
      <slot name="icon" />
      <input
        v-model.trim="getValue"
        v-maska
        class="input__field"
        type="tel"
        autocomplete="tel"
        data-maska="+38(0##) ### ## ##"
        :class="[
          { filled: getValue },
          { error: validate.$error && validate.$dirty },
        ]"
        :placeholder="placeholder"
        @focus="setPlaceholder"
        @blur="onBlur"
      />
      <span class="input__caption" :class="{ required }">
        {{ _T(caption) }}
      </span>
      <div v-show="validate.$error && validate.$dirty" class="input__error-img">
        <img src="~~/assets/icons/error-input.svg" alt="error attention" />
      </div>
    </div>
    <div class="input__error-caption-w">
      <p v-if="validate.$error && validate.$dirty" class="input__error-caption">
        {{ validate.$errors[0].$message }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { vMaska } from "maska";

const props = defineProps({
  caption: { type: String, required: true },
  modelValue: { type: String, required: false },
  validate: { type: Object, required: true },
  required: { type: Boolean, required: false, default: true },
  wide: { type: Boolean, required: false, default: false },
  mobileMaxWidth: { type: Boolean, required: false, default: false },
  isLandingPage: { type: Boolean, required: false, default: false },
  withIcon: { type: Boolean, required: false, default: false },
});

const emits = defineEmits(["update:modelValue"]);

const placeholder = ref("");

const getValue = computed({
  get: () => props.modelValue,
  set: (value) => emits("update:modelValue", value),
});

function setPlaceholder() {
  placeholder.value = "+38(0";
}

function onBlur() {
  placeholder.value = "";
  props.validate.$touch();
}
</script>

<style lang="scss" scoped></style>
